<template>
  <div>
    <ValidationObserver v-slot="{ errors, validate }">
      <vx-card class="mb-base">
        <form @submit.prevent="submit(validate)">
          <FormLayout>
            <FormLabel/>
            <FormContent>
              <vs-chip
                  class="w-3/12 h-12 text-xl"
                  v-if="formData.is_approved"
                  :color="'success'"
                >อนุมัติ</vs-chip
                >
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>ชื่อโปรโมชัน *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="ชื่อโปรโมชัน"
                rules="required"
              >
                <vs-input 
                  :disabled="!canDo"
                  v-model="formData.title" 
                  class="w-full" 
                  name="title" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>โครงการ *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="โครงการ" 
                rules="required">
                <div>
                  <vs-select
                    :disabled="!canDo"
                    v-model="formData.project_id"
                    name="โครงการ"
                    class="selectExample"
                    icon="arrow_downward"
                    placeholder="เลือกโครงการ"
                    autocomplete
                  >
                    <vs-select-item
                      v-for="(item, index) in projects.data"
                      :value="item.id"
                      :text="item.title"
                      :key="index"
                    />
                  </vs-select>
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">{{
                      errors[0]
                    }}</span>
                </div>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>ระบุช่วงเวลา *</span>
            </FormLabel>
            <FormContent>
              <div class="flex flex-col md:flex-row md:-mx-2">
                <div class="w-full mb-2 md:w-1/2 md:px-2">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="วันที่เริ่มต้น"
                    rules="required"
                  >
                    <datepicker
                      :disabled="!canDo"
                      v-model="formData.start_date"
                      :minimum-view="'day'"
                      :maximum-view="'year'"
                      :highlighted="{
                        dates: [
                          // Highlight an array of dates
                          formData.start_date
                            ? new Date(formData.start_date)
                            : new Date(),
                        ],
                      }"
                      :disabled-dates="formData.end_date ? {
                        from: new Date(formData.end_date)
                      } : {}"
                      format="dd MMMM yyyy"
                      placeholder="วันที่เริ่มต้น"
                      typeable
                    />
                    <span 
                      v-if="errors.length > 0" 
                      class="text-danger text-sm">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="w-full md:w-1/2 md:px-2">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="วันที่สิ้นสุด"
                    rules="required"
                  >
                    <datepicker
                      :disabled="!canDo"
                      v-model="formData.end_date"
                      :minimum-view="'day'"
                      :maximum-view="'year'"
                      :highlighted="{
                        dates: [
                          // Highlight an array of dates
                          formData.end_date
                            ? new Date(formData.end_date)
                            : new Date(),
                        ],
                      }"
                      :disabled-dates="formData.start_date ? {
                        to: new Date(formData.start_date)
                      } : {}"
                      format="dd MMMM yyyy"
                      placeholder="วันที่สิ้นสุด"
                      typeable
                    />
                    <span 
                      v-if="errors.length > 0" 
                      class="text-danger text-sm">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รายละเอียดโปรโมชัน</span>
            </FormLabel>
            <FormContent>
              <vs-textarea 
                :class="!canDo ? 'textarea-disabled' : ''"
                :disabled="!canDo"
                v-model="formData.description"
                rows="10"
                class="w-full" />
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>URL พิเศษ</span><br >
              <span 
                class="text-warning text-sm"
              >**ถ้าไม่กำหนดจะลิงค์ไปยังหน้าโครงการอัตโนมัติ</span
              >
            </FormLabel>
            <FormContent>
              <vs-input
                :disabled="!canDo"
                v-model="formData.external_link"
                class="w-full"
                name="externalLink"
              />
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รูปปก เลือกอย่างน้อย 1 รูป</span><br >
            </FormLabel>
            <FormContent>
              <div class="w-full">
                <ValidationProvider 
                  v-slot="{ errors }" 
                  name="รูปปก" 
                  rules="required">
                  <div
                    class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-6"
                  >
                    <div
                      class="flex flex-col h-full"
                      v-for="(item, index) in gallery" 
                      :key="index"
                    >
                      <div class="flex-1">
                        <cld-image 
                          :public-id="item.image.public_id" 
                          :cloud-name="env.VUE_APP_CLOUDINARY_CLOUD_NAME" 
                          responsive/>
                      </div>
                      <div 
                        class="w-full mt-2">
                        <vs-radio
                          :disabled="!canDo"
                          vs-name="select-radio"
                          v-model="selectThumbnail"
                          :vs-value="index"
                        >เลือกรูปนี้
                        </vs-radio>
                      </div>
                    </div>
                  </div>
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">{{
                      errors[0]
                    }}
                  </span>
                </ValidationProvider>
              </div>
            </FormContent>
          </FormLayout>
          <FormLayout v-if="canApprove">
            <FormLabel> <span>แอดมินอนุมัติ</span><br > </FormLabel>
            <FormContent>
              <vs-switch 
                :disabled="!canDo"
                v-model="formData.is_approved" 
                class="mt-2" />
            </FormContent>
          </FormLayout>
        </form>
      </vx-card>
      <vx-card>
        <FormLayout>
          <FormLabel>
            <span>สร้างโดย</span>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <span class="mt-2"> {{formData.created_by}}</span>
          </div>
        </FormLayout>
        <FormLayout>
          <FormLabel>
            <span>แก้ไขล่าสุดโดย</span>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <span class="mt-2"> {{formData.updated_by}}</span>
          </div>
        </FormLayout>
        <FormLayout>
          <FormLabel>
            <span>แก้ไขล่าสุดวันที่</span>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
          </div>
        </FormLayout>
        <FormLayout v-if="canDo">
          <FormLabel/>
          <FormContent>
            <vs-button 
              class="w-1/3" 
              @click="submit(validate)">{{btnSubmitLabel}}</vs-button>
          </FormContent>
        </FormLayout>
      </vx-card>
    </ValidationObserver>
  </div>
</template>

<script>
import { onMounted, computed, ref, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { CldImage } from 'cloudinary-vue'
// import find from 'lodash/find'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import Datepicker from 'vuejs-datepicker'
import useDatepicker from '@/use/useDatepicker'
import usePromotionUtil from '@/use/usePromotionUtil'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'
import Draggable from 'vuedraggable'
import env from '@/env'
// import Vue from 'vue'

export default {
  name: 'PromotionForm',
  components: {
    Datepicker,
    ValidationObserver,
    CldImage,
    Draggable
  },
  setup(props, ctx) {
    const { success: notifySuccess, error: notifyError } = useNotify(ctx)
    const {
      root: { $store, $router, $vs },
    } = ctx
    const {
      initialData,
      formData,
      projects,
      fetchByRouteId,
      fetchProjectGalleryList,
      btnSubmitLabel,
      isDateRangeValid,
    } = usePromotionUtil(ctx)
    const crudForm = useCrud(ctx, 'promotion', {
      fetchOnMount: false,
    })
    crudForm.routePrefix.value = 'property-promotion'

    onMounted(async () => {
      const queryLang = get($router.currentRoute.query, 'lang', null)
      if (queryLang) await $store.dispatch('locale/changeLang', queryLang)

      await $vs.loading()
      await fetchProjectGalleryList()
      await fetchByRouteId()
      await $store.dispatch('promotion/fetch')
      await $vs.loading.close()
    })

    const selectThumbnail = ref(0)

    const updateItem = ({ validate }) => {
      validate().then(() => {
        $vs.loading()

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let createdBy = formData.created_by
        const updatedBy = email

        // add user created by
        if (!createdBy) {
          createdBy = email
        }
        
        $store
          .dispatch('promotion/updateItem', {
            initialData,
            formData: {
              ...omit(formData, ['created_at', 'updated_at']),
              created_by: createdBy,
              updated_by: updatedBy,
            }
          })
          .then(() => {
            $router.push({ name: 'property-promotion' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            notifyError({ text: JSON.stringify(error) })
          })
      })
    }

    const gallery = computed(() => {
      const selectProject = projects.data.find((project) => project.id === formData.project_id)

      const projectFiles = get(selectProject, ['project_files'], [])
      const projectGallery = projectFiles.filter((file) => file.attribute_code === 'gallery' || file.attribute_code === 'promotion_gallery')
      
      return orderBy(projectGallery, [(file) => get(file, ['image', 'image_index'], 0)], ['desc'])
    })

    const canDo = computed(() => {
      return ctx.root.$acl.check('editor') || (!formData.is_approved && ctx.root.$acl.check('sale'))
    })

    const canApprove = computed(() => {
      return ctx.root.$acl.check('editor')
    })

    const submit = (validate) => {
      if (!isDateRangeValid()) {
        notifyError({ text: 'มีโปรโมชันสำหรับโปรเจคที่เลือก ในช่วงเวลาเดียวกันแล้ว' })
        return $vs.loading.close()
      }
      // assign thumbnail value
      validate().then((result) => {
        if (!result) {
          notifyError({
            text: 'กรุณาตรวจสอบข้อมูลที่กรอก',
          })
          return
        }

        $vs.loading()

        // const thumbnail = find(formData.gallery, ['image_selected', true])
        const selectId = selectThumbnail.value
        const thumbnail = !isNil(selectId) ? get(gallery, ['value', selectId, 'image'], null) : null

        formData.thumbnail = thumbnail.public_id

        // add user created by
        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let createdBy = formData.created_by
        const updatedBy = email

        // add user created by
        if (!createdBy) {
          createdBy = email
        }

        if (formData.id) {
          updateItem({ validate })
        } else {
          crudForm.addItem({validate}, {
            ...omit(formData, ['created_at', 'updated_at']),
            created_by: createdBy,
            updated_by: updatedBy
          })
        }

        $vs.loading.close()
      })
    }

    watch(
      () => formData.project_id,
      async () => {
        if (formData.thumbnail) {
          await setTimeout(
            function () {
              selectThumbnail.value = 0

              gallery.value.forEach((file, index) => {
                if (file.image.public_id === formData.thumbnail) {
                  selectThumbnail.value = index
                }
              })
            }.bind(this),
            100,
          )
        }
      },
    )

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)

    return {
      ...crudForm,
      updateItem,
      formData,
      projects,
      submit,
      useDatepicker: useDatepicker(),
      baseFolder,
      env,
      gallery,
      selectThumbnail,
      btnSubmitLabel,
      canDo,
      canApprove
    }
  },
}
</script>

<style lang="scss">
  .textarea-disabled {
    opacity: 0.5;
  }
</style>
